












import { Vue, Component } from 'vue-property-decorator';
import { globalStore } from '@/store/modules/global';
import { DataLabel } from '@/models/study/models';
import { Filter } from '@/api/ApiClientV2';

@Component({})
export default class ParticipantList extends Vue {
  DataLabel = DataLabel;

  get filter(): Filter {
    return {
      // explicitly set to 'unknown' to not show anything
      application: globalStore.selection.application?.id ?? 'unknown',
    };
  }
}
