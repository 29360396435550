























import { Vue, Component, Watch } from 'vue-property-decorator';
import { Participant } from '@/models/study/models';
import { globalStore } from '@/store/modules/global';
import { ParticipantState } from '../interfaces';
import { Context } from '@/api/ApiClientV2';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface TableData {
  state: string;
  number: number;
}

@Component({})
export default class ParticipantList extends Vue {
  Participant = Participant;

  data: TableData[] = [];
  total: number | null = null;
  destroySubject = new Subject<void>();

  @Watch('$route.query')
  onRouteChanged(): void {
    this.load();
  }

  get columns(): {
    field: string;
    label: string;
    subheading: string | number;
  }[] {
    return [
      {
        field: 'state',
        label: this.$tc('study.participant.fields.state'),
        subheading: 'total',
      },
      {
        field: 'number',
        label: this.$tc('common.number'),
        subheading: this.total,
      },
    ];
  }

  async created(): Promise<void> {
    await this.load();
    this.$apiv2
      .getRefreshStream()
      .pipe(takeUntil(this.destroySubject))
      .subscribe(() => {
        this.load();
      });
  }

  destroyed(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  async load(): Promise<void> {
    const loading = this.$buefy.loading.open({});
    try {
      const setting = globalStore.clientAppSetting('config');
      const states: ParticipantState[] =
        setting?.value?.participant_states ?? [];
      const promises = states.map(async state => {
        const number = await this.getNumber(state.state);
        return {
          state: state.state,
          number,
        };
      });
      this.total = await this.getNumber(undefined);
      this.data = await Promise.all(promises);
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loading.close();
  }

  async getNumber(state: string): Promise<number> {
    try {
      const context: Context = {
        filter: {
          application: globalStore.selection.application?.id ?? 'unknown',
          state,
        },
        pagination: {
          page: 1,
          pageSize: 1,
        },
      };
      const response = await this.$apiv2.getList<Participant>(
        Participant,
        context,
      );
      return response.size;
    } catch (error) {
      this.$errorHandler.handleError(error);
      return 0;
    }
  }
}
